// React
import React, { useCallback, useEffect, useState } from 'react';
// Navigation
import { useNavigate } from 'react-router-dom';
// FHIR
import { StatusSelect, StatusMenu } from '@fyrstain/fhir-front-library';
import { TestReport } from 'fhir/r5';
// Client
import Client from 'fhir-kit-client';
// Translation
import i18n from 'i18next';

///////////////////////////////
//          Props           //
///////////////////////////////

interface TestOperationStatusSelectorProps {
    // Choose between providing the TestScript resource or its ID
    testReport?: TestReport;
    testReportId?: string;
    // The step of the TestReport to update
    testStep: string;
    // The type of update to perform: status or result
    updateType: 'status' | 'result';
    // Callback function to handle the case where the patch operation is performed later
    onPatchFallback?: (testStep: string, message: string) => void;
}

const TestOperationStatusSelector: React.FC<TestOperationStatusSelectorProps> = (configs) => {

    ///////////////////////////////
    //          States           //
    ///////////////////////////////

    const [selectedStatus, setSelectedStatus] = useState<string>('');
    const [testReport, setTestReport] = useState<any>(null);
    const [isStatusEmpty, setIsStatusEmpty] = useState<boolean>(true);

    ///////////////////////////////
    //          Constants        //
    ///////////////////////////////

    const testReportResultOptions: StatusMenu[] = [
        { status: 'success', statusMessage: 'pass' },
        { status: 'info', statusMessage: 'skip' },
        { status: 'errorStatus', statusMessage: "fail" },
        { status: 'advisory', statusMessage: 'warning' },
        { status: 'errorStatus', statusMessage: 'error' },
    ];

    const testReportStatusOptions: StatusMenu[] = [
        { status: 'success', statusMessage: 'completed' },
        { status: 'suspended', statusMessage: 'in-progress' },
        { status: 'suspended', statusMessage: "waiting" },
        { status: 'advisory', statusMessage: 'stopped' },
        { status: 'errorStatus', statusMessage: 'entered-in-error' },
    ];

    ///////////////////////////////
    //           Client          //
    ///////////////////////////////

    const fhirClient = new Client({
        baseUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir'
    });

    ///////////////////////////////
    //          Functions        //
    ///////////////////////////////

    const navigate = useNavigate();

    /**
     * Function to navigate to the error page
     */
    const onError = useCallback(() => {
        navigate("/Error");
    }, [navigate]);

    /**
     * Function to get the path and status message of a test step in a test report
     * 
     * @param testStep 
     * @param testReport 
     * @param updateType 
     * @returns 
     */
    const getTestStepPathAndStatusMessage = (testStep: string, testReport: any, updateType: string) => {
        let statusMessage = '';
        let patchPath = '';
        const testStepKey = testStep;
        const findTestObject = (testArray: any[], key: string) => {
            return testArray.find((test: any) => test.id === key);
        };
        const findActionObject = (actionArray: any[], key: string, type: 'operation' | 'assert') => {
            return actionArray.find((action: any) => action[type]?.id === key);
        };
        if (testReport) {
            const testObject = findTestObject(testReport.test || [], testStepKey);
            if (testObject) {
                patchPath = `/test/${testReport.test.indexOf(testObject)}/${updateType}`;
                statusMessage = testObject[updateType];
            } else {
                const setupActionObject = findActionObject(testReport.setup?.action || [], testStepKey, 'operation');
                if (setupActionObject?.operation) {
                    patchPath = `/setup/action/${testReport.setup.action.indexOf(setupActionObject)}/operation/${updateType}`;
                    statusMessage = setupActionObject.operation[updateType];
                } else {
                    const setupActionAssertObject = findActionObject(testReport.setup?.action || [], testStepKey, 'assert');
                    if (setupActionAssertObject?.assert) {
                        patchPath = `/setup/action/${testReport.setup.action.indexOf(setupActionAssertObject)}/assert/${updateType}`;
                        statusMessage = setupActionAssertObject.assert[updateType];
                    } else {
                        const teardownActionObject = findActionObject(testReport.teardown?.action || [], testStepKey, 'operation');
                        if (teardownActionObject?.operation) {
                            patchPath = `/teardown/action/${testReport.teardown.action.indexOf(teardownActionObject)}/operation/${updateType}`;
                            statusMessage = teardownActionObject.operation[updateType];
                        } else if (testReport.test) {
                            for (let i = 0; i < testReport.test.length; i++) {
                                const testActionObject = findActionObject(testReport.test[i]?.action || [], testStepKey, 'operation') ||
                                    findActionObject(testReport.test[i]?.action || [], testStepKey, 'assert');
                                if (testActionObject?.operation) {
                                    patchPath = `/test/${i}/action/${testReport.test[i].action.indexOf(testActionObject)}/operation/${updateType}`;
                                    statusMessage = testActionObject.operation[updateType];
                                    break;
                                } else if (testActionObject?.assert) {
                                    patchPath = `/test/${i}/action/${testReport.test[i].action.indexOf(testActionObject)}/assert/${updateType}`;
                                    statusMessage = testActionObject.assert[updateType];
                                    break;
                                }
                            }
                        }
                    }
                }
            }
        }
        return { patchPath, statusMessage };
    };

    /**
     * Function to create a patch document for a test step in a test report
     * 
     * @param testStep 
     * @param statusMessage 
     * @returns 
     */
    const createPatchDocument = (testStep: string, statusMessage: string) => {
        const { patchPath } = getTestStepPathAndStatusMessage(testStep, testReport, configs.updateType);
        const patchDocument = [
            {
                op: 'replace',
                path: patchPath,
                value: statusMessage,
            },
        ];
        return patchDocument;
    };

    /**
     * Function to patch a resource
     * 
     * @param patchDocument 
     * @returns 
     */
    const patchResource = async (patchDocument: any) => {
        try {
            const updatedTestReport = await fhirClient.patch({
                resourceType: 'TestReport',
                id: testReport.id,
                JSONPatch: patchDocument,
            });
            return updatedTestReport;
        } catch (error) {
            onError();
        }
    };

    /**
     * Function to handle the status of a test step
     * 
     * @param testStep 
     * @param newStatus 
     */
    const handleTestStepStatus = async (testStep: string, newStatus: string) => {
        const { patchPath } = getTestStepPathAndStatusMessage(testStep, testReport, 'status');
        if (patchPath) {
            const patchDocument = createPatchDocument(testStep, newStatus);
            await patchResource(patchDocument);
            setSelectedStatus(newStatus);
        } else {
            configs.onPatchFallback?.(testStep, newStatus);
        }
    };

    /**
     * Function to get the status options based on the update type
     * 
     */
    const getStatusOptions = () => {
        if (configs.updateType === 'status') {
            return testReportStatusOptions;
        } else {
            return testReportResultOptions;
        }
    };

    /**
     * Function to fetch the test operation status
     * 
     */
    const fetchTestOperationStatus = async () => {
        try {
            let reportId = "";
            if (configs.testReportId && configs.testReportId !== "") {
                reportId = configs.testReportId;
            } else {
                reportId = configs.testReport?.id ?? "";
            }
            let testReportResponse = await fhirClient.read({
                resourceType: 'TestReport',
                id: reportId,
            });
            if (testReportResponse) {
                const statusMessage = getTestStepPathAndStatusMessage(configs.testStep, testReportResponse, configs.updateType).statusMessage || '';
                if (statusMessage) {
                    setSelectedStatus(statusMessage);
                    setTestReport(testReportResponse);
                    setIsStatusEmpty(false);
                } else {
                    setIsStatusEmpty(true);
                }
            }
        } catch (error) {
            onError();
        }
    };

    ///////////////////////////////
    //          LifeCycle        //
    ///////////////////////////////

    /**
     * Fetch the test operation status when the component mounts
     * 
     */
    useEffect(() => {
        fetchTestOperationStatus();
    }, [configs.testStep, configs.updateType]);

    ///////////////////////////////
    //          Content          //
    ///////////////////////////////

    const updateTypeTranslation = configs.updateType === 'status'
        ? i18n.t('defaultselectoption.choosestatus')
        : i18n.t('defaultselectoption.chooseresult');

    return (
        <div>
            <StatusSelect
                defaultSelectOption={isStatusEmpty
                    ? updateTypeTranslation
                    : selectedStatus
                }
                statusMessageArray={getStatusOptions()}
                onChange={(selectedStatus) => {
                    handleTestStepStatus(configs.testStep, selectedStatus);
                }}
                language={i18n.t}
                updateTypeTranslation={updateTypeTranslation}
            />
        </div>
    );
};

export default TestOperationStatusSelector;