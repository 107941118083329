// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.testReportDetails_formTextLabel__SUXzD {
    margin: 1rem 1rem 0 1.5rem;
    text-align: justify;
    line-height: 1.2rem;
}

.testReportDetails_alert__xPA0R {
    width: 97%;
    margin: auto;
}

.testReportDetails_alertTitle__hfcD- {
    margin-left: 1rem;
}

.testReportDetails_form__\\+IIb6 {
    margin: 2rem 0 0 0;
}

.testReportDetails_noOperationCard__\\+V1kA {
    background-color: white !important;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.testReportDetails_infoMessage__fNknT {
    margin-left: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/testReportDetails/testReportDetails.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kCAAkC;IAClC,aAAa;IACb,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".formTextLabel {\r\n    margin: 1rem 1rem 0 1.5rem;\r\n    text-align: justify;\r\n    line-height: 1.2rem;\r\n}\r\n\r\n.alert {\r\n    width: 97%;\r\n    margin: auto;\r\n}\r\n\r\n.alertTitle {\r\n    margin-left: 1rem;\r\n}\r\n\r\n.form {\r\n    margin: 2rem 0 0 0;\r\n}\r\n\r\n.noOperationCard {\r\n    background-color: white !important;\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    align-items: center;\r\n}\r\n\r\n.infoMessage {\r\n    margin-left: 1rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formTextLabel": `testReportDetails_formTextLabel__SUXzD`,
	"alert": `testReportDetails_alert__xPA0R`,
	"alertTitle": `testReportDetails_alertTitle__hfcD-`,
	"form": `testReportDetails_form__+IIb6`,
	"noOperationCard": `testReportDetails_noOperationCard__+V1kA`,
	"infoMessage": `testReportDetails_infoMessage__fNknT`
};
export default ___CSS_LOADER_EXPORT___;
