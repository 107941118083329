// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.systemsSelection_main__XBBkf {
    width: 80%;
    margin: auto;
}

.systemsSelection_card__kQnI3 {
    margin-bottom: 3rem;
}

.systemsSelection_formSelect__WBMx2 {
    margin: 0 2rem 0 1.5rem;
    width: 100%;
}

.systemsSelection_formTextLabel__uM2kY {
    margin: 0 1rem 0.4rem 1.5rem;
    text-align: justify;
}

.systemsSelection_formLabel__ulIBf {
    margin: 0 1rem 1rem 0;
}

.systemsSelection_automatableSwitch__uP5RW {
    margin: 1rem 0 0 1.5rem;
}

.systemsSelection_btnContainer__MVoMa {
    margin-left: 1.5rem;
}

.systemsSelection_warningMessage__dEHXV {
    width: 87.5%;
    margin-left: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/systemsSelection/systemsSelection.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;IACvB,WAAW;AACf;;AAEA;IACI,4BAA4B;IAC5B,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".main {\r\n    width: 80%;\r\n    margin: auto;\r\n}\r\n\r\n.card {\r\n    margin-bottom: 3rem;\r\n}\r\n\r\n.formSelect {\r\n    margin: 0 2rem 0 1.5rem;\r\n    width: 100%;\r\n}\r\n\r\n.formTextLabel {\r\n    margin: 0 1rem 0.4rem 1.5rem;\r\n    text-align: justify;\r\n}\r\n\r\n.formLabel {\r\n    margin: 0 1rem 1rem 0;\r\n}\r\n\r\n.automatableSwitch {\r\n    margin: 1rem 0 0 1.5rem;\r\n}\r\n\r\n.btnContainer {\r\n    margin-left: 1.5rem;\r\n}\r\n\r\n.warningMessage {\r\n    width: 87.5%;\r\n    margin-left: 1.5rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `systemsSelection_main__XBBkf`,
	"card": `systemsSelection_card__kQnI3`,
	"formSelect": `systemsSelection_formSelect__WBMx2`,
	"formTextLabel": `systemsSelection_formTextLabel__uM2kY`,
	"formLabel": `systemsSelection_formLabel__ulIBf`,
	"automatableSwitch": `systemsSelection_automatableSwitch__uP5RW`,
	"btnContainer": `systemsSelection_btnContainer__MVoMa`,
	"warningMessage": `systemsSelection_warningMessage__dEHXV`
};
export default ___CSS_LOADER_EXPORT___;
