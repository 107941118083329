// React
import React, { useCallback, useEffect, useState } from 'react';
// Navigation
import { useNavigate } from 'react-router-dom';
// FHIR
import { TestReport } from 'fhir/r5';
// Client
import Client from 'fhir-kit-client';
// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

/////////////////////////////
//        Props            //
/////////////////////////////

interface TestMessageEditionProps {
    // Choose between providing the TestReport resource or its ID
    testReport?: TestReport;
    testReportId?: string;
    // The step of the test to edit
    testStep: string;
    // Callback function to handle the case where the patch operation is performed later
    onPatchFallback?: (testStep: string, message: string) => void;
}

const TestMessageEdition: React.FC<TestMessageEditionProps> = (configs) => {

    /////////////////////////////
    //          State          //
    /////////////////////////////

    const [message, setMessage] = useState<string>('');
    const [testReport, setTestReport] = useState<any>(null);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isMessageEmpty, setIsMessageEmpty] = useState<boolean>(true);

    /////////////////////////////
    //          Client         //
    /////////////////////////////

    const fhirClient = new Client({
        baseUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir'
    });

    /////////////////////////////
    //        Functions        //
    /////////////////////////////

    const navigate = useNavigate();

    /**
     * Function to navigate to the Error page
     */
    const onError = useCallback(() => {
        navigate("/Error");
    }, [navigate]);

    /**
     * Function to get the path and message of a test step in a TestReport resource
     * 
     * @param testStep 
     * @param testReport 
     * @returns 
     */
    const getTestStepPathAndMessage = (testStep: string, testReport: any) => {
        let message = '';
        let patchPath = '';
        const testStepKey = testStep;
        const findTestObject = (testArray: any[], key: string) => {
            return testArray.find((test: any) => test.id === key);
        };
        const findActionObject = (actionArray: any[], key: string, type: 'operation' | 'assert') => {
            return actionArray.find((action: any) => action[type]?.id === key);
        };
        if (testReport) {
            const testObject = findTestObject(testReport.test || [], testStepKey);
            if (testObject) {
                patchPath = `/test/${testReport.test.indexOf(testObject)}/message`;
                message = testObject.message;
            } else {
                const setupActionObject = findActionObject(testReport.setup?.action || [], testStepKey, 'operation');
                if (setupActionObject?.operation) {
                    patchPath = `/setup/action/${testReport.setup.action.indexOf(setupActionObject)}/operation/message`;
                    message = setupActionObject.operation.message;
                } else {
                    const setupActionAssertObject = findActionObject(testReport.setup?.action || [], testStepKey, 'assert');
                    if (setupActionAssertObject?.assert) {
                        patchPath = `/setup/action/${testReport.setup.action.indexOf(setupActionAssertObject)}/assert/message`;
                        message = setupActionAssertObject.assert.message;
                    } else {
                        const teardownActionObject = findActionObject(testReport.teardown?.action || [], testStepKey, 'operation');
                        if (teardownActionObject?.operation) {
                            patchPath = `/teardown/action/${testReport.teardown.action.indexOf(teardownActionObject)}/operation/message`;
                            message = teardownActionObject.operation.message;
                        } else if (testReport.test) {
                            for (let i = 0; i < testReport.test.length; i++) {
                                const testActionObject = findActionObject(testReport.test[i]?.action || [], testStepKey, 'operation') ||
                                    findActionObject(testReport.test[i]?.action || [], testStepKey, 'assert');
                                if (testActionObject?.operation) {
                                    patchPath = `/test/${i}/action/${testReport.test[i].action.indexOf(testActionObject)}/operation/message`;
                                    message = testActionObject.operation.message;
                                    break;
                                } else if (testActionObject?.assert) {
                                    patchPath = `/test/${i}/action/${testReport.test[i].action.indexOf(testActionObject)}/assert/message`;
                                    message = testActionObject.assert.message;
                                    break;
                                }
                            }
                        }
                    }
                }
            }
        }
        return { patchPath, message };
    };

    /**
     * Create a JSON Patch document to replace the message of a test step in a TestReport resource
     * 
     * @param testStep 
     * @param message 
     * @returns 
     */
    const createPatchDocument = (testStep: string, message: string) => {
        const { patchPath } = getTestStepPathAndMessage(testStep, testReport);
        const patchDocument = [
            {
                op: 'replace',
                path: patchPath,
                value: message,
            },
        ];
        return patchDocument;
    };

    /**
     * Patch a TestReport resource with a JSON Patch document
     * 
     * @param patchDocument 
     * @returns 
     */
    const patchResource = async (patchDocument: any) => {
        try {
            const updatedTestReport = await fhirClient.patch({
                resourceType: 'TestReport',
                id: testReport.id,
                JSONPatch: patchDocument,
            });
            return updatedTestReport;
        } catch (error) {
            onError();
        }
    };

    /**
     * Fetch the message of a test step in a TestReport resource
     * 
     */
    const fetchTestOperationMessage = async () => {
        try {
            let reportId = "";
            if (configs.testReportId && configs.testReportId !== "") {
                reportId = configs.testReportId;
            } else {
                reportId = configs.testReport?.id ?? "";
            }
            let testReportResponse = await fhirClient.read({
                resourceType: 'TestReport',
                id: reportId ?? "",
            });
            if (testReportResponse) {
                const { message } = getTestStepPathAndMessage(configs.testStep, testReportResponse);
                if (message) {
                    setMessage(message);
                    setTestReport(testReportResponse);
                    setIsMessageEmpty(false);
                } else {
                    setIsMessageEmpty(true);
                }
            }
        } catch (error) {
            onError();
        }
    };

    /////////////////////////////
    //         LifeCycle       //
    /////////////////////////////

    /**
     * Fetch the message of the test step when the component mounts
     */
    useEffect(() => {
        fetchTestOperationMessage();
    }, [configs.testStep]);

    /////////////////////////////
    //        Handlers         //
    /////////////////////////////

    /**
     * Handle the patch operation of a test step in a TestReport resource
     * 
     * @param testStep 
     * @param newMessage 
     */
    const handleTestStep = async (testStep: string, newMessage: string) => {
        const { patchPath } = getTestStepPathAndMessage(testStep, testReport);
        if (patchPath) {
            const patchDocument = createPatchDocument(testStep, newMessage);
            const updatedTestReport = await patchResource(patchDocument);
            if (updatedTestReport) {
                setTestReport(updatedTestReport);
                const { message: updatedMessage } = getTestStepPathAndMessage(testStep, updatedTestReport);
                setMessage(updatedMessage);
            }
        } else if (configs.onPatchFallback) {
            configs.onPatchFallback(testStep, newMessage);
        }
    };

    /**
     * Handle the click event on the edit icon
     */
    const handleIconClick = () => {
        setIsEditing(true);
    };

    /**
     * Handle the change event on the input field
     * 
     * @param e 
     */
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(e.target.value);
    };

    /**
     * Handle the blur event on the input field
     */
    const handleInputBlur = async () => {
        setIsEditing(false);
        await handleTestStep(configs.testStep, message);
        setIsMessageEmpty(message.trim() === '');
    };

    /////////////////////////////
    //         Content         //
    /////////////////////////////

    return (
        <div>
            {isEditing ? (
                <input
                    type="text"
                    value={message}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    autoFocus
                />
            ) : (
                <span onClick={handleIconClick}>
                    {isMessageEmpty ?
                        <FontAwesomeIcon
                            icon={faPencilAlt}
                            className="actionIcon"
                        />
                        : message
                    }
                </span>
            )}
        </div>
    );
};

export default TestMessageEdition;