// Components
import { FhirStatus, Proof, StatusAlert, StatusIcon, Thread, Title } from "@fyrstain/fhir-front-library";
import PandoraPage from '../../components/PandoraPage/PandoraPage';
import TestOperationSection from 'src/components/TestReport/TestOperationSection/TestOperationSection';
import TestReportResultAlert from '../../components/TestReport/TestReportResultAlert/TestReportResultAlert';
// Authentication
import UserService from "src/services/UserService";
// Dayjs
import dayjs from 'dayjs';
// FHIR
import Client from 'fhir-kit-client';
import { Bundle, TestReport, TestReportSetupAction, TestReportTeardownAction, TestReportTestAction, TestScript } from "fhir/r5";
// Translation
import i18n from "i18next";
// React
import { FunctionComponent, useCallback, useEffect, useState } from "react";
// React Bootstrap
import { Card, Form } from "react-bootstrap";
// React Router
import { useNavigate, useParams } from "react-router-dom";
// Style
import styles from "./testReportDetails.module.css";

const TestReportDetails: FunctionComponent = () => {

    /////////////////////////////////////////////
    //                 State                   //
    /////////////////////////////////////////////

    const { testReportId } = useParams();
    const [tests, setTests] = useState([] as { toggle: boolean, id: string, name: string, description: string, operations: { operation: TestReportTestAction, asserts: TestReportTestAction[] }[] }[]);
    const [setups, setSetups] = useState([] as { toggle: boolean, operation: TestReportSetupAction, asserts: TestReportSetupAction[] }[]);
    const [teardowns, setTeardowns] = useState([] as { toggle: boolean, id: string, operation: TestReportTeardownAction }[]);
    const [report, setReport] = useState({} as TestReport);
    const [script, setScript] = useState({} as TestScript);
    const [loading, setLoading] = useState(false);

    const [serverLabel, setServerLabel] = useState("");
    const [clientLabel, setClientLabel] = useState("");
    const [proxychannel, setProxyChannel] = useState("");

    const [isManualTest, setIsManualTest] = useState(false);

    /////////////////////////////////////////////
    //                  Client                 //
    /////////////////////////////////////////////

    const fhirClient = new Client({
        baseUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir'
    });

    /////////////////////////////////////////////
    //                 Functions               //
    /////////////////////////////////////////////

    const navigate = useNavigate();

    /**
     * Function to get the detail of the operation.
     * 
     * @param detail    the detail to get.
     */
    function onDetail(detail: string | undefined) {
        let url = '';
        if (detail?.startsWith("Bundle/")) {
            url = "/ValidationReport/" + detail.split('/')[1];
        }
        if (detail?.startsWith("Parameters/")) {
            url = "/ExchangeDetails/" + detail.split('/')[1];
        }
        return url;
    }

    /**
     * Function to navigate to the error page.
     */
    const onError = useCallback(() => {
        navigate("/Error");
    }, [navigate]);


    /**
     * Retrieve setups operations and asserts to populate the table.
     * 
     * @param report    the report to the Test report.
     */
    function retrieveSetups(report: TestReport) {
        const result: { toggle: boolean, operation: TestReportSetupAction, asserts: TestReportSetupAction[] }[] = [];
        report.setup?.action.filter(a => a.operation).forEach(a => {
            const isPass = a.operation?.result === 'pass';
            result.push({ "toggle": !isPass, "operation": a, asserts: report.setup?.action.filter(action => action.assert?.id?.includes(a.operation?.id ?? '')) ?? [] })
        })
        return result;
    }

    /**
     * Retrieve operations and asserts tests to populate the table.
     * 
     * @param report    the report to the Test report.
     */
    function retrieveTests(report: TestReport) {
        const result: { toggle: boolean, id: string, name: string, description: string, operations: { operation: TestReportTestAction, asserts: TestReportTestAction[] }[] }[] = [];
        const testMap = new Map<string, { toggle: boolean, id: string, name: string, description: string, operations: { operation: TestReportTestAction, asserts: TestReportTestAction[] }[] }>();
        report.test?.forEach(test => {
            const testId = test.id ?? 'N/A';
            const testName = test.name ?? '';
            const testDescription = test.description ?? '';
            test.action.forEach(action => {
                if (action.operation) {
                    const isPass = action.operation.result === 'pass';
                    const operationId = action.operation.id ?? 'N/A';
                    const asserts = test.action.filter(a => a.assert?.id?.includes(operationId)) ?? [];
                    if (testMap.has(testId)) {
                        const existingTest = testMap.get(testId);
                        if (existingTest) {
                            existingTest.operations.push({ operation: action, asserts });
                        }
                    } else {
                        testMap.set(testId, { toggle: !isPass, id: testId, name: testName, description: testDescription, operations: [{ operation: action, asserts }] });
                    }
                }
            });
        });
        testMap.forEach(test => result.push(test));
        return result;
    }

    /**
     * Retrieve teardowns operations to populate the table.
     * 
     * @param report 
     */
    function retrieveTeardowns(report: TestReport) {
        const result: { toggle: boolean, id: string, operation: TestReportTeardownAction }[] = [];
        report.teardown?.action.filter(a => a.operation).forEach(a => {
            const isPass = a.operation?.result === 'pass';
            result.push({
                "toggle": !isPass, "id": a.operation?.id ?? 'N/A', "operation": a

            })
        })
        return result;
    }

    /**
    * Load Report from the back to populate the table.
    * 
    * @returns the promise of a Test report.
    */
    async function loadReport() {
        setLoading(true);
        try {
            const response = await fhirClient.read({
                resourceType: 'TestReport',
                id: testReportId ?? '',
            })
            const testReport: TestReport = response as TestReport;
            setReport(testReport);
            setSetups(retrieveSetups(testReport));
            setTests(retrieveTests(testReport));
            setTeardowns(retrieveTeardowns(testReport));
            const response2 = await fhirClient.search({
                resourceType: 'TestScript',
                searchParams: {
                    "url": testReport.testScript,
                }
            })
            const bundle: Bundle = response2 as Bundle;
            const script: TestScript = bundle.entry?.at(0)?.resource as TestScript;
            if (script) {
                setScript(script);
            }
            setClientLabel(testReport.participant?.find(participant => participant.type === "client")?.display ?? "N/A");
            setServerLabel(testReport.participant?.find(participant => participant.type === "server")?.display ?? "N/A");
            setProxyChannel(testReport.extension?.find(extension => extension.url === "ProxyChannelName")?.valueString ?? "N/A");
            setLoading(false);
        } catch (error) {
            onError();
        }
    }

    /**
     * handle the addition of a setup operation after the addition of a row
     * 
     * @param newSetup 
     */
    const handleAddSetup = (newSetup: any) => {
        const setupOperations = retrieveSetups(newSetup);
        if (setupOperations.length > 0) {
            const lastIndex = setupOperations.length - 1;
            setSetups(prevSetups => [...prevSetups, setupOperations[lastIndex]]);
        }
    };

    /**
     * handle the addition of a test operation after the addition of a row
     * 
     * @param newTest 
     */
    const handleAddTest = (newTest: any) => {
        const testOperations = retrieveTests(newTest);
        if (testOperations.length > 0) {
            testOperations.forEach(result => {
                if (result && result.operations) {
                    const newTest = result.operations[result.operations.length - 1];
                    const newTestId = newTest.operation?.operation?.id;
                    const testIndex = tests.findIndex(test => test.id === result.id);
                    if (testIndex !== -1) {
                        const operationIndex = tests[testIndex].operations.findIndex(op => op.operation.operation?.id === newTestId);
                        if (operationIndex === -1) {
                            setTests(prevTests => {
                                const newTests = [...prevTests];
                                newTests[testIndex].operations.push(newTest);
                                return newTests;
                            });
                        } else {
                            setTests(prevTests => {
                                const newTests = [...prevTests];
                                newTests[testIndex].operations[operationIndex] = newTest;
                                return newTests;
                            })
                        }
                    }
                }
            });
        }
    };

    /**
     * handle the addition of a teardown operation after the addition of a row
     * 
     * @param newTeardown 
     */
    const handleAddTeardown = (newTeardown: any) => {
        const teardownOperations = retrieveTeardowns(newTeardown);
        if (teardownOperations.length > 0) {
            const lastIndex = teardownOperations.length - 1;
            setTeardowns(prevTeardowns => [...prevTeardowns, teardownOperations[lastIndex]]);
        }
    };

    /**
     * handle the addition of a setup assert after the addition of a row
     * 
     * @param newSetup 
     */
    const handleAddSetupAssert = (newSetup: any) => {
        const setupResults = retrieveSetups(newSetup);
        if (setupResults.length > 0) {
            setupResults.forEach(result => {
                if (result && result.operation && result.operation.operation) {
                    const newAssert = result.asserts[result.asserts.length - 1];
                    const newAssertId = newAssert?.assert?.id;
                    const setupIndex = setups.findIndex(setup => setup.operation.operation?.id === result.operation.operation?.id);
                    if (setupIndex !== -1) {
                        const assertIndex = setups[setupIndex].asserts.findIndex(assert => assert.assert?.id === newAssertId);
                        if (assertIndex === -1) {
                            setSetups(prevSetups => {
                                const newSetups = [...prevSetups];
                                newSetups[setupIndex].asserts.push(newAssert);
                                return newSetups;
                            });
                        } else {
                            setSetups(prevSetups => {
                                const newSetups = [...prevSetups];
                                newSetups[setupIndex].asserts[assertIndex] = newAssert;
                                return newSetups;
                            });
                        }
                    }
                }
            });
        }
    };

    /**
     * handle the addition of a test assert after the addition of a row
     * 
     * @param newTest 
     */
    const handleAddTestAssert = (newTest: any) => {
        const testResults = retrieveTests(newTest);
        if (testResults.length > 0) {
            testResults.forEach(result => {
                if (result && result.operations) {
                    result.operations.forEach(operation => {
                        if (operation && operation.operation && operation.operation.operation) {
                            const newAssert = operation.asserts[operation.asserts.length - 1];
                            const newAssertId = newAssert?.assert?.id;
                            const testIndex = tests.findIndex(test => test.id === result.id);
                            if (testIndex !== -1) {
                                const operationIndex = tests[testIndex].operations.findIndex(op => op.operation.operation?.id === operation.operation.operation?.id);
                                if (operationIndex !== -1) {
                                    const assertIndex = tests[testIndex].operations[operationIndex].asserts.findIndex(assert => assert.assert?.id === newAssertId);
                                    if (assertIndex === -1) {
                                        setTests(prevTests => {
                                            const newTests = [...prevTests];
                                            newTests[testIndex].operations[operationIndex].asserts.push(newAssert);
                                            return newTests;
                                        });
                                    } else {
                                        setTests(prevTests => {
                                            const newTests = [...prevTests];
                                            newTests[testIndex].operations[operationIndex].asserts[assertIndex] = newAssert;
                                            return newTests;
                                        });
                                    }
                                }
                            }
                        }
                    });
                }
            });
        }
    }

    /////////////////////////////////////////////
    //                 Lifecycle               //
    /////////////////////////////////////////////

    /** 
     * Load the report when the page is loaded.
     * 
     */
    useEffect(() => {
        loadReport();
    }, []);

    /** 
     * Check if the test is manual or automated.
     * 
     */
    useEffect(() => {
        const automatedTestExtension = report.extension?.find(ext => ext.url === "http://isis.com/test/StructureDefinition/EXT-AutomatedTest");
        if (automatedTestExtension) {
            setIsManualTest(!automatedTestExtension.valueBoolean);
        }
    }, [report]);

    /////////////////////////////////////////////
    //                Content                  //
    /////////////////////////////////////////////

    return (
        <PandoraPage
            titleKey={isManualTest ? 'title.manualtestreport' : 'title.testreport'}
            loading={loading}
            needsLogin={true}
        >
            <>
                <Card>
                    <Card.Header>
                        <div className="spaceBetweenContainer">
                            <Title level={2} content={'Informations'} />
                            {isManualTest &&
                                <div className="displayFlexCenter">
                                    <Proof
                                        serverUrl='https://integ.fyrstain.com/test-engine'
                                        userIdentifier={UserService.getUsername()}
                                        onError={() => { }}
                                        testReportId={report.id}
                                        testScriptId={script.id}
                                        testStep='label.generalproof'
                                        language={i18n.t}
                                    />
                                    <Thread
                                        serverUrl='https://integ.fyrstain.com/test-engine'
                                        userIdentifier={UserService.getUsername()}
                                        onError={() => { }}
                                        testReportId={report.id}
                                        testScriptId={script.id}
                                        testStep='label.generalcomment'
                                        language={i18n.t}
                                    />
                                </div>
                            }
                        </div>
                    </Card.Header>
                    <Card.Body className="cardBody">
                        <div className={styles.alert}>
                            {isManualTest ? (
                                <TestReportResultAlert
                                    testReportId={report.id}
                                />
                            ) : (
                                <StatusAlert
                                    status={FhirStatus[report.result as keyof typeof FhirStatus]}
                                >
                                    <div>
                                        <StatusIcon
                                            status={FhirStatus[report.result as keyof typeof FhirStatus]}
                                        />
                                        <strong className={styles.alertTitle}>
                                            {report.id} / {report.name}
                                        </strong>
                                    </div>
                                </StatusAlert>
                            )}
                        </div>
                        <div className={styles.form}>
                            <div>
                                <Form.Label className={styles.formTextLabel}>
                                    <strong>
                                        {i18n.t('testreport.card.description.title')}
                                    </strong>
                                </Form.Label>
                                <Form.Text>
                                    {script.description}
                                </Form.Text>
                            </div>
                            <div>
                                <Form.Label className={styles.formTextLabel}>
                                    <strong>
                                        {i18n.t('testreport.table.started')} :
                                    </strong>
                                </Form.Label>
                                <Form.Text>
                                    {dayjs(report.issued).fromNow()}
                                </Form.Text>
                            </div>
                            <div>
                                <Form.Label className={styles.formTextLabel}>
                                    <strong>
                                        {i18n.t('testreport.table.duration')} :
                                    </strong>
                                </Form.Label>
                                <Form.Text>
                                    {Math.round(dayjs(report.meta?.lastUpdated).diff(report.issued, "second", true)) + i18n.t('testreport.table.seconds')}
                                </Form.Text>
                            </div>
                            <div>
                                <Form.Label className={styles.formTextLabel}>
                                    <strong>
                                        {i18n.t('label.client')} :
                                    </strong>
                                </Form.Label>
                                <Form.Text>
                                    {clientLabel}
                                </Form.Text>
                            </div>
                            <div>
                                <Form.Label className={styles.formTextLabel}>
                                    <strong>
                                        {i18n.t('label.server')} :
                                    </strong>
                                </Form.Label>
                                <Form.Text>
                                    {serverLabel}
                                </Form.Text>
                            </div>
                            <div>
                                <Form.Label className={styles.formTextLabel}>
                                    <strong>
                                        {i18n.t('label.proxychannel')} :
                                    </strong>
                                </Form.Label>
                                <Form.Text>
                                    {proxychannel}
                                </Form.Text>
                            </div>
                        </div>
                    </Card.Body>
                </Card>

                <TestOperationSection
                    operations={setups}
                    asserts={setups.map(setup => setup.asserts).flat()}
                    testReport={report}
                    testScript={script}
                    testStepHeaderOperation='Setup'
                    testStepOperation={(setup: any) => setup.operation.operation?.id ?? ''}
                    testStepAssert={(assert: any) => assert.assert?.id ?? ''}
                    isManualTest={isManualTest}
                    titleSection='Setup'
                    addTestOperation='setupOperation'
                    addTestAssert='setupAssert'
                    onError={onError}
                    onDetail={onDetail}
                    onAddOperation={handleAddSetup}
                    onAddAssert={handleAddSetupAssert}
                />

                <Card className="section">
                    <Card.Header>
                        <div className="spaceBetweenContainer">
                            <Title level={2} content={'Tests'} />
                        </div>
                    </Card.Header>
                    <Card.Body className="cardBody">
                        {isManualTest || tests.length > 0 ? (
                            tests.map((test, index) => (
                                <TestOperationSection
                                    key={test.id || index}
                                    operations={test.operations}
                                    asserts={test.operations.map(operation => operation.asserts).flat()}
                                    testReport={report}
                                    testScript={script}
                                    testStepHeaderOperation={test.id}
                                    testStepOperation={(test: any) => test.operation?.operation?.id ?? ''}
                                    testStepAssert={(assert: any) => assert.assert?.id ?? ''}
                                    isManualTest={isManualTest}
                                    titleSection={test.id}
                                    addTestOperation='testOperation'
                                    addTestAssert='testAssert'
                                    testId={test.id}
                                    onAddOperation={handleAddTest}
                                    onAddAssert={handleAddTestAssert}
                                    onError={onError}
                                    onDetail={onDetail}
                                />
                            ))
                        ) : (
                            !isManualTest && (
                                <Card>
                                    <Card.Header className={styles.noOperationCard}>
                                        <Title level={2} content={'Test'} />
                                        <b className={styles.infoMessage}>
                                            ({i18n.t('infomessage.nooperation')})
                                        </b>
                                    </Card.Header>
                                </Card>
                            )
                        )}
                        {(isManualTest && tests.length === 0) && (
                            <TestOperationSection
                                key="manualTest"
                                operations={tests}
                                asserts={tests.map(test => test.operations).flat().map(operation => operation.asserts).flat()}
                                testReport={report}
                                testScript={script}
                                testStepHeaderOperation='Test'
                                testStepOperation={(test: any) => test.operation?.operation?.id ?? ''}
                                testStepAssert={(assert: any) => assert.assert?.id ?? ''}
                                isManualTest={isManualTest}
                                titleSection='Test'
                                addTestOperation='testOperation'
                                addTestAssert='testAssert'
                                onAddOperation={handleAddTest}
                                onAddAssert={handleAddTestAssert}
                                onError={onError}
                                onDetail={onDetail}
                            />
                        )}
                    </Card.Body>
                </Card>

                <TestOperationSection
                    operations={teardowns}
                    testReport={report}
                    testScript={script}
                    testStepHeaderOperation='Teardown'
                    testStepOperation={(teardown: any) => teardown.operation?.operation?.id ?? ''}
                    isManualTest={isManualTest}
                    titleSection='Teardown'
                    isTeardown={true}
                    addTestOperation='teardownOperation'
                    onAddOperation={handleAddTeardown}
                    onError={onError}
                    onDetail={onDetail}
                />

            </>
        </PandoraPage >
    );
};

export default TestReportDetails;