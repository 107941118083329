import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Client from 'fhir-kit-client';
import { Bundle, TestPlan } from "fhir/r5";
import i18n from "i18next";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";
import { useNavigate, useParams } from "react-router-dom";
import PandoraPage from '../../components/PandoraPage/PandoraPage';
import { Title, FhirStatus, StatusTag } from "@fyrstain/fhir-front-library";
import styles from "./testPlanViewer.module.css";

const TestPlanViewer: FunctionComponent = () => {

    /////////////////////////////////////
    //             State               //
    /////////////////////////////////////

    const [loading, setLoading] = useState(false);

    /////////////////////////////////////
    //      Constants / ValueSet       //
    /////////////////////////////////////

    const [columns, setColumns] = useState([
        { text: "ID", dataField: "id" },
        { text: i18n.t('label.name'), dataField: "title" },
        {
            text: "Action",
            dataField: "id",
            formatter: (cell: string) => {
                return <div>
                    <FontAwesomeIcon
                        onClick={OnTestScriptView(cell)}
                        icon={faEye}
                        className="actionIcon"
                        aria-disabled={true}
                    />
                </div>;
            },
        }
    ] as ColumnDescription[]);
    const [data, setData] = useState([] as {}[]);

    const { testPlanId } = useParams();
    const [testPlanName, setTestPlanName] = useState("");
    const [testPlanDescription, setTestPlanDescription] = useState("");
    const [testPlanVersion, setTestPlanVersion] = useState("");
    const [testPlanStatus, setTestPlanStatus] = useState("");

    /////////////////////////////////////
    //             Client              //
    /////////////////////////////////////

    const fhirClient = new Client({
        baseUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir'
    });

    /////////////////////////////////////
    //             Actions             //
    /////////////////////////////////////

    useEffect(() => {
        load();
    }, []);

    async function load() {
        setLoading(true);
        await loadTestPlanInformations();
        setLoading(false);
    }

    const navigate = useNavigate();

    /**
     * Redirect to the System selection page.
     * 
     * @param id ID of the TestPlan to launch.
     */
    const onLaunch = useCallback((id: string) => {
        navigate("/TestPlan/SystemSelection/" + id);
    }, [navigate]);

    /**
     * Launch the TestPlan, ie redirect to the System selection page.
     * 
     * @param id ID of the TestPlan to launch.
     */
    function launchTestPlan(id: string) {
        onLaunch(id);
    }

    /**
     * Redirect to the TestScript viewer page.
     * 
     * @param id ID of the TestScript to view.
     */
    const OnTestScriptView = useCallback((id: string) => () => {
        navigate("/TestScriptViewer/" + id);
    }, [navigate]);

    /**
     * Load the TestScript informations to display in fields.
     */
    async function loadTestPlanInformations() {
        try {
            const scripts: {}[] = []
            const response = await fhirClient.search({
                resourceType: "TestPlan",
                searchParams: {
                    "_id": testPlanId ?? "",
                    "_include": "TestPlan:testscript",
                }
            });
            if (response.resourceType !== 'Bundle') {
                throw Error(response.statusText);
            }
            const bundle: Bundle = response as Bundle;

            bundle.entry?.forEach(entry => {
                if ('TestPlan' === entry.resource?.resourceType) {
                    const testPlan: TestPlan = entry.resource as TestPlan;
                    setTestPlanName(testPlan.title ?? "undefined");
                    setTestPlanDescription(testPlan.description ?? "undefined");
                    setTestPlanVersion(testPlan.version ?? "undefined");
                    setTestPlanStatus(testPlan.status ?? "undefined");
                } else if ('TestScript' === entry.resource?.resourceType) {
                    scripts.push(entry.resource);
                }
            })
            setData(scripts);
        } catch (error) {
            console.log(error);
            onError();
        }
        return [];
    }

    const onError = useCallback(() => {
        navigate("/Error");
    }, [navigate]);

    /////////////////////////////////////
    //          Page Content           //
    /////////////////////////////////////

    return (
        <PandoraPage titleKey='title.testplanviewer' loading={loading} needsLogin={true} >
            <div className={styles.main}>
                <Card className={styles.card}>
                    <Card.Header>
                        <Title level={2} content={'Informations'} />
                    </Card.Header>
                    <Card.Body className="cardBody">
                        <div>
                            <div className="spaceBetweenContainer">
                                <div className="mediumContainer">
                                    <div>
                                        <Form.Label className={styles.label}>
                                            <strong >
                                                ID :
                                            </strong>
                                        </Form.Label>
                                        <Form.Text>
                                            {testPlanId}
                                        </Form.Text>
                                    </div>
                                    <div>
                                        <Form.Label className={styles.label}>
                                            <strong>
                                                {i18n.t('label.name')} :
                                            </strong>
                                        </Form.Label>
                                        <Form.Text>
                                            {testPlanName}
                                        </Form.Text>
                                    </div>
                                </div>
                                <div className="mediumContainer">
                                    <div>
                                        <Form.Label className={styles.label}>
                                            <strong>
                                                Version :
                                            </strong>
                                        </Form.Label>
                                        <Form.Text>
                                            {testPlanVersion}
                                        </Form.Text>
                                    </div>
                                    <div className="spaceBetweenContainer badgeContainer">
                                        <Form.Label className={styles.label}>
                                            <strong>
                                                {i18n.t('label.status')} :
                                            </strong>
                                        </Form.Label>
                                        <Form.Text className={styles.formText}>
                                            <StatusTag
                                                status={FhirStatus[testPlanStatus as keyof typeof FhirStatus]}
                                                statusMessage={testPlanStatus}
                                            />
                                        </Form.Text>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.formLabel}>
                                <Form.Label className={styles.label}>
                                    <strong>
                                        {i18n.t('label.generaldescription')} :
                                    </strong>
                                </Form.Label>
                                <Form.Text>
                                    {testPlanDescription}
                                </Form.Text>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <div className="section">
                    <BootstrapTable
                        keyField="name"
                        data={data}
                        columns={columns}
                        pagination={paginationFactory({ paginationSize: 20 })} />
                </div>
                <Button
                    className="button"
                    variant="danger"
                    onClick={() => launchTestPlan(testPlanId ?? "")}
                >
                    {i18n.t('button.launch')}
                </Button>
            </div>
        </PandoraPage>
    );
};

export default TestPlanViewer;
