// React
import React, { useCallback, useEffect, useState } from 'react';
// FontAwesome
import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Components
import TestMessageEdition from '../TestMessageEdition/TestMessageEdition';
import TestOperationStatusSelector from '../TestOperationStatusSelector/TestOperationStatusSelector';
// Styles
import styles from './AddTestOperation.module.css';
// FHIR
import { TestReport } from 'fhir/r5';
// Client
import Client from 'fhir-kit-client';
// Navigation
import { useNavigate } from 'react-router-dom';
// Translation
import i18n from 'i18next';

/////////////////////////////
//          Props          //
/////////////////////////////
interface AddTestOperationProps {
    // If the operation is an assert or an operation
    operationType: 'operation' | 'assert';
    // Choose between providing the TestReport resource or its ID
    testReport?: TestReport;
    testReportId?: string;
    // The step of the test
    testStep: string;
    // Callbacks to update the parent component for the addition of the operation
    onAddOperation?: (operation: any) => void;
    // Callbacks to update the parent component for the addition of the assert
    onAddAssert?: (assert: any) => void;
    // If the test report has operations
    hasOperations: boolean;
    // The id of the operation
    operationId?: string;
    // The id of the test
    testId?: string;
}

const AddTestOperation: React.FC<AddTestOperationProps> = (configs) => {

    /////////////////////////////
    //          States         //
    /////////////////////////////

    const [rows, setRows] = useState<Array<{ id: string, status: string, message: string }>>([]);
    const [testReport, setTestReport] = useState<any>(null);
    const [showHeaderAndRow, setShowHeaderAndRow] = useState(false);
    const [showAddButton, setShowAddButton] = useState(true);

    /////////////////////////////
    //         Constants       //
    /////////////////////////////

    const patchDocument: any[] = [];
    const patchData: { [key: string]: { result?: string; message?: string } } = {};

    /////////////////////////////
    //          Client         //
    /////////////////////////////

    const fhirClient = new Client({
        baseUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir'
    });

    /////////////////////////////
    //        Functions        //
    /////////////////////////////

    const navigate = useNavigate();

    /** 
     * Redirects to the Error page
     * 
     */
    const onError = useCallback(() => {
        navigate("/Error");
    }, [navigate]);

    /**
     * generated an id for the different test steps
     * 
     * @param testStep 
     * @param index 
     * @param operationId 
     * @returns 
     */
    function generateId(testStep: string, index: number, operationId?: string): string {
        if (testStep === 'setupAssert' || testStep === 'testAssert') {
            return `${operationId}-ASS-${String(index).padStart(2, '0')}`;
        }
        switch (testStep) {
            case 'setupOperation':
                return `SET-${index.toString().padStart(2, '0')}`;
            case 'teardownOperation':
                return `TED-${index.toString().padStart(2, '0')}`;
            case 'testOperation':
                return `TES-${index.toString().padStart(2, '0')}`;
            default:
                throw new Error(`Invalid testStep: ${testStep}`);
        }
    };

    /**
     * Get the existing operation ids
     * 
     */
    const getExistingOperationIds = () => {
        if (!testReport) {
            return { setup: [], teardown: [], test: [] };
        }
        const { setup, teardown, test } = testReport;
        const setupIds = setup?.action?.flatMap((action: any) => {
            const id = action[configs.operationType]?.id;
            return id ? [id] : [];
        }) || [];
        const teardownIds = teardown?.action?.flatMap((action: any) => {
            const id = action[configs.operationType]?.id;
            return id ? [id] : [];
        }) || [];
        const testIds = test?.flatMap((test: any) => {
            return test.action?.flatMap((action: any) => {
                const id = action[configs.operationType]?.id;
                return id ? [id] : [];
            }) || [];
        }) || [];
        return { setup: setupIds, teardown: teardownIds, test: testIds };
    };

    /** 
      * Get or create an actionArray and a patch path
      * 
      * @param testStep 
      * @param testReport 
      * @param testId 
      * @returns 
      */
    const getOrCreateActionArrayAndPatchPath = (testStep: string, testReport: any, testId?: string) => {
        if (!testReport) {
            testReport = {};
        }
        let actionArray;
        let patchPath;
        switch (testStep) {
            case 'setupOperation':
            case 'setupAssert':
                if (!testReport.setup) {
                    testReport.setup = { action: [] };
                } else if (!testReport.setup.action) {
                    testReport.setup.action = [];
                }
                actionArray = testReport.setup.action;
                patchPath = '/setup/action/-';
                break;
            case 'teardownOperation':
                if (!testReport.teardown) {
                    testReport.teardown = { action: [] };
                } else if (!testReport.teardown.action) {
                    testReport.teardown.action = [];
                }
                actionArray = testReport.teardown.action;
                patchPath = '/teardown/action/-';
                break;
            case 'testOperation':
            // TODO Implemente the testId
            // if (!testReport.test) {
            //     testReport.test = [];
            // }
            // let testIndex = testReport.test.findIndex((test: any) => test.id === testId);
            // if (testIndex === -1) {
            //     testReport.test.push({ id: testId, action: [] });
            //     testIndex = testReport.test.length - 1;
            // }
            // actionArray = testReport.test[testIndex].action;
            // patchPath = `/test/${testIndex}/action/-`;
            // break;
            case 'testAssert':
                if (!testReport.test) {
                    testReport.test = [];
                }
                // TODO implemente the index of the test 
                actionArray = testReport.test.flatMap((test: any) => test.action);
                patchPath = `/test/0/action/-`;
                break;
            default:
                throw new Error(`Invalid testStep: ${testStep}`);
        }
        return { actionArray, patchPath };
    };

    /**
     * Create a patch document
     * 
     * @param testReport 
     * @param operationType 
     * @param id 
     * @param result 
     * @param message 
     * @param index 
     * @returns 
     */
    const createPatchDocument = (testReport: any, operationType: string, id: string, result: string, message: string, index?: number) => {
        const { patchPath } = getOrCreateActionArrayAndPatchPath(configs.testStep, testReport, configs.testId);
        const newOperation = {
            [operationType]: {
                id,
                result: result || '',
                message: message || '',
            },
        };
        const existingPatchIndex = patchDocument.findIndex(patch => patch.value[operationType]?.id === id);
        if (existingPatchIndex !== -1) {
            return;
        }
        const fullPath = index !== undefined ? patchPath.replace('-', index.toString()) : patchPath;
        patchDocument.push({
            op: 'add',
            path: fullPath,
            value: newOperation,
        });
        return patchDocument;
    };

    /**
     * Patch a resource
     * 
     * @param patchDocument
     */
    const patchResource = async (patchDocument: any) => {
        try {
            const updatedTestReport = await fhirClient.patch({
                resourceType: 'TestReport',
                id: (configs.testReportId || (configs.testReport && configs.testReport.id)) ?? '',
                JSONPatch: patchDocument,
            });
            return updatedTestReport;
        } catch (error) {
            onError();
            return null;
        }
    };

    /**
     * Fetch the test report
     * 
     * @param testReportId 
     * @param testReport 
     */
    const fetchTestReport = async (testReportId: string | undefined, testReport: TestReport | undefined) => {
        try {
            const id = testReportId || testReport?.id;
            if (!id) return;

            const response = await fhirClient.read({
                resourceType: 'TestReport',
                id: id,
            });
            setTestReport(response);
        } catch (error) {
            onError();
        }
    };

    /**
     * Ensure that the setup actions exist
     * 
     * @param testReport 
     */
    const ensureSetupActionsExist = (testReport: any) => {
        if (!testReport.setup) {
            testReport.setup = { action: [] };
        } else if (!testReport.setup.action) {
            testReport.setup.action = [];
        }
    };

    /**
     * Ensure that the test actions exist
     * 
     * @param test 
     */
    const ensureTestActionsExist = (test: any) => {
        if (!test.action) {
            test.action = [];
        }
    };

    /**
     * Insert an assert
     * 
     * @param actions 
     * @param testStep 
     * @param newAssert 
     * @param section 
     * @param result 
     * @param message 
     */
    const insertAssert = (actions: any, testStep: string, newAssert: any, result: string, message: string) => {
        for (let i = 0; i < actions.length; i++) {
            const action = actions[i];
            if (action.operation && action.operation.id === testStep.split('-ASS-')[0]) {
                let insertIndex = i + 1;
                while (insertIndex < actions.length && actions[insertIndex] && actions[insertIndex].assert && actions[insertIndex].assert.id.startsWith(testStep.split('-ASS-')[0])) {
                    insertIndex++;
                }
                actions.splice(insertIndex, 0, newAssert);
                createPatchDocument(testReport, configs.operationType, testStep, result || '', message || '', insertIndex);
                break;
            }
        }
    };

    /**
     * Insert an operation
     * 
     * @param actions 
     * @param newOperation 
     * @param section 
     * @param result 
     * @param message 
     * @returns 
     */
    const insertOperation = (actions: any, newOperation: any, result: string, message: string) => {
        const existingOperationIndex = actions.findIndex((action: any) => action.operation && action.operation.id === newOperation.operation.id);
        if (existingOperationIndex !== -1) {
            return;
        }
        actions.push(newOperation);
        createPatchDocument(testReport, configs.operationType, newOperation.operation.id, result || '', message || '');
    };

    /**
     * 
     * 
     * @param actions 
     * @param testStep 
     * @returns 
     */
    const operationExists = (actions: any, testStep: string) => {
        return actions.some((action: any) => action.operation && action.operation.id === testStep);
    };

    /**
     * Apply the patch document
     * 
     */
    const applyPatchDocument = async () => {
        for (const row of rows) {
            if (!patchData[row.id] || !patchData[row.id].result || !patchData[row.id].message) {
                alert('Please complete all fields before validating !');
                return;
            }
        }
        for (const [testStep, data] of Object.entries(patchData)) {
            const { result, message } = data;
            if (configs.operationType === 'assert') {
                const newAssert = { assert: { id: testStep, result: result || '', message: message || '' } };
                ensureSetupActionsExist(testReport);
                ensureTestActionsExist(testReport);
                insertAssert(testReport.setup.action, testStep, newAssert, result || '', message || '');
                for (const test of testReport.test) {
                    ensureTestActionsExist(test);
                    insertAssert(test.action, testStep, newAssert, result || '', message || '');
                }
            } else {
                const newOperationSetup = { operation: { id: testStep, result: result || '', message: message || '' } };
                const newOperationTest = { operation: { id: testStep, result: result || '', message: message || '' } };
                ensureSetupActionsExist(testReport);
                ensureTestActionsExist(testReport);
                if (!operationExists(testReport.setup.action, testStep)) {
                    insertOperation(testReport.setup.action, newOperationSetup, result || '', message || '');
                }
                for (const test of testReport.test) {
                    ensureTestActionsExist(test);
                    if (!operationExists(test.action, testStep)) {
                        insertOperation(test.action, newOperationTest, result || '', message || '');
                    }
                }
            }
        }
        if (patchDocument.length > 0) {
            try {
                const updatedTestReport = await patchResource(patchDocument);
                if (updatedTestReport) {
                    setTestReport(updatedTestReport);
                    setRows([]);
                    if (configs.operationType === 'operation' && configs.onAddOperation) {
                        configs.onAddOperation(updatedTestReport);
                    } else if (configs.operationType === 'assert' && configs.onAddAssert) {
                        configs.onAddAssert(updatedTestReport);
                    }
                }
            } catch (error) {
                onError();
            }
        }
        setShowAddButton(true);
    };

    /**
     * Add a row
     * 
     * @param testStep 
     * @param operationId 
     */
    const addRow = (testStep: string, operationId?: string) => {
        const existingIds = getExistingOperationIds();
        let idsForStep;
        switch (testStep) {
            case 'setupOperation':
                idsForStep = existingIds.setup;
                break;
            case 'teardownOperation':
                idsForStep = existingIds.teardown;
                break;
            case 'testOperation':
                idsForStep = existingIds.test;
                break;
            case 'setupAssert':
                idsForStep = existingIds.setup.filter((id: any) => id.includes('-ASS-') && id.startsWith(operationId || ''));
                break;
            case 'testAssert':
                idsForStep = existingIds.test.filter((id: any) => id.includes('-ASS-') && id.startsWith(operationId || ''));
                break;
            default:
                throw new Error(`Invalid testStep: ${testStep}`);
        }
        const existingIndexes = idsForStep.map((id: any) => {
            const match = id.match(/\d+/g);
            return match ? parseInt(match[match.length - 1], 10) : 0;
        });
        const maxIndex = existingIndexes.length > 0 ? Math.max(...existingIndexes) : 0;
        let newIndex = maxIndex + 1;
        let newId = generateId(testStep, newIndex, operationId);
        while (idsForStep.includes(newId)) {
            newIndex += 1;
            newId = generateId(testStep, newIndex, operationId);
        }
        const newRow = { id: newId, status: '', message: '' };
        const operationIndex = rows.findIndex(row => row.id === operationId);
        if (operationIndex !== -1) {
            const updatedRows = [
                ...rows.slice(0, operationIndex + 1),
                newRow,
                ...rows.slice(operationIndex + 1)
            ];
            setRows(updatedRows);
        } else {
            setRows([...rows, newRow]);
        }
        if (configs.operationType === 'operation' && configs.onAddOperation) {
            configs.onAddOperation(newRow);
        } else if (configs.operationType === 'assert' && configs.onAddAssert) {
            configs.onAddAssert(newRow);
        }
    };

    /**
     * Add the header and a row
     * 
     */
    const addHeaderAndRow = () => {
        const operationId = configs.operationId || '';
        addRow(configs.testStep, operationId);
        setShowHeaderAndRow(true);
        setShowAddButton(false);
    };

    /////////////////////////////
    //         Callbacks       //
    /////////////////////////////

    /**
     * Handle the patch fallback message
     * 
     * @param id 
     * @param message 
     */
    const handlePatchFallbackMessage = (id: string, message: string) => {
        if (!patchData[id]) {
            patchData[id] = {};
        }
        patchData[id].message = message;
    }

    /**
     * Handle the patch fallback status
     * 
     * @param id 
     * @param status 
     */
    const handlePatchFallbackStatus = (id: string, status: string) => {
        if (!patchData[id]) {
            patchData[id] = {};
        }
        patchData[id].result = status;
    };

    /////////////////////////////
    //         LifeCycle       //
    /////////////////////////////

    /**
     * Get the test report
     */
    useEffect(() => {
        fetchTestReport(configs.testReportId, configs.testReport);
    }, [configs.testReportId, configs.testReport]);

    /////////////////////////////
    //         Content         //
    /////////////////////////////

    return (
        <div
            className={configs.operationType === 'operation' ? "container col-md-12 panel panel-default panel-body" : ""}
        >
            {(!configs.hasOperations && showHeaderAndRow) && (
                <table className="table table-condensed table-striped">
                    <thead>
                        <tr>
                            <th className={styles.columnsWidthOperation}>
                                {i18n.t('table.row.name')}
                            </th>
                            <th className={styles.smallColumnsWidthOperation}>
                                {i18n.t('table.row.result')}
                            </th>
                            <th className={styles.largeColumnsWidthOperation}>
                                Messages
                            </th>
                            <th className={styles.smallColumnsWidthOperation}>
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, index) => (
                            <tr key={index}>
                                <td className={styles.columnsWidthOperation}>{row.id}</td>
                                <td className={styles.smallColumnsWidthOperation}>
                                    <TestOperationStatusSelector
                                        testReportId={configs.testReportId || configs.testReport?.id}
                                        testStep={row.id}
                                        updateType="result"
                                        onPatchFallback={handlePatchFallbackStatus}
                                    />
                                </td>
                                <td className={styles.largeColumnsWidthOperation}>
                                    <TestMessageEdition
                                        testReportId={configs.testReportId || configs.testReport?.id}
                                        testStep={row.id}
                                        onPatchFallback={handlePatchFallbackMessage}
                                    />
                                </td>
                                <td>
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        onClick={applyPatchDocument}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            <table className=" table table-condensed table-striped">
                <tbody>
                    {configs.hasOperations && (
                        rows.map((row, index) => (
                            <React.Fragment key={index}>
                                <tr
                                    className={configs.operationType === 'operation' ? styles.operationRow : ''}
                                >
                                    <td className={styles.columnsWidthOperation}>
                                        {row.id}
                                    </td>
                                    <td className={styles.smallColumnsWidthOperation}>
                                        <TestOperationStatusSelector
                                            testReportId={configs.testReportId || configs.testReport?.id}
                                            testStep={row.id}
                                            updateType="result"
                                            onPatchFallback={handlePatchFallbackStatus}
                                        />
                                    </td>
                                    <td className={styles.largeColumnsWidthOperation}>
                                        <TestMessageEdition
                                            testReportId={configs.testReportId || configs.testReport?.id}
                                            testStep={row.id}
                                            onPatchFallback={handlePatchFallbackMessage}
                                        />
                                    </td>
                                    <td>
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className="actionIcon"
                                            onClick={applyPatchDocument}
                                        />
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))
                    )}
                </tbody>
            </table>
            {
                showAddButton && (
                    <div
                        onClick={addHeaderAndRow}
                        className={styles.addOperationButton}
                    >
                        <FontAwesomeIcon
                            icon={faPlus}
                            className={styles.addOperationIcon}
                        />
                        <strong>
                            {configs.operationType === 'operation' ? i18n.t('text.addoperation') : i18n.t('text.addassert')}
                        </strong>
                    </div>
                )
            }
        </div >
    );
};

export default AddTestOperation;