
// FontAwesome
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Components
import { FhirStatus, Proof, StatusTag, Thread, Title } from '@fyrstain/fhir-front-library';
import AddTestOperation from '../AddOperationButton/AddTestOperation';
import TestMessageEdition from '../TestMessageEdition/TestMessageEdition';
import TestOperationStatusSelector from '../TestOperationStatusSelector/TestOperationStatusSelector';
// FHIR
import { TestReport, TestReportSetupAction, TestReportTeardownAction, TestReportTestAction, TestScript } from 'fhir/r5';
// Translation
import i18n from 'i18next';
// React
import { useReducer, useState } from 'react';
// React Bootstrap
import { Accordion, Card } from 'react-bootstrap';
// Authentication
import UserService from 'src/services/UserService';
// Styles
import styles from './TestOperationSection.module.css';

////////////////////////
//      Props         //
////////////////////////
interface TestOperationSectionProps {
    // The operations to display
    operations: any;
    // The asserts to display
    asserts?: any;
    // Choose between providing the TestReport resource or its ID
    testReport?: TestReport;
    testReportId?: string;
    // Choose between providing the TestScript resource or its ID
    testScript?: TestScript;
    testScriptId?: string;
    // The test step operation to display in the section header
    testStepHeaderOperation: string;
    // The test step of the operations
    testStepOperation: any;
    // The test step of the asserts
    testStepAssert?: any;
    // If the test is a manual test
    isManualTest: boolean;
    // The title of the section
    titleSection: string;
    // If the section is a teardown section
    isTeardown?: boolean;
    // The add operation function
    addTestOperation: any;
    // The add assert function
    addTestAssert?: any;
    // The id of the test
    testId?: string;
    // The function to get the detail of an operation or an assert
    onAddOperation?: (operation: any) => void;
    // The function to get the detail of an assert
    onAddAssert?: (assert: any) => void;
    // Function to call when an error occurs
    onError: () => void;
    // The function to get the detail of an operation or an assert
    onDetail: (detail: string) => string;
}

const TestOperationSection: React.FC<TestOperationSectionProps> = (configs) => {

    /////////////////////////////
    //          States         //
    /////////////////////////////

    const [headerAdded, setHeaderAdded] = useState(false);
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    /////////////////////////////
    //        Functions        //
    /////////////////////////////

    /**
     * Handle the addition of an operation.
     * 
     * @param operation 
     */
    const handleAddOperation = (operation: any) => {
        if (configs.onAddOperation) {
            configs.onAddOperation(operation);
        }
    };

    /**
     * Handle the addition of an assert.
     * 
     * @param assert
     */
    const handleAddAssert = (assert: any) => {
        if (configs.onAddAssert) {
            configs.onAddAssert(assert);
        }
    };

    /**
    * Determine the overall status for a set of operations.
    * 
    * @param operations The operations to check.
    * @returns The overall status.
    */
    function prioritizeStatus(operations: (TestReportSetupAction | TestReportTestAction | TestReportTeardownAction)[]): string {
        let status = 'pass';
        let hasSkip = false;
        for (const operation of operations) {
            if (!operation) continue;
            switch (operation.operation?.result) {
                case 'fail':
                    return 'fail';
                case 'error':
                    if (status !== 'fail') {
                        status = 'error';
                    }
                    break;
                case 'warning':
                    if (status !== 'fail' && status !== 'error') {
                        status = 'warning';
                    }
                    break;
                case 'skip':
                    hasSkip = true;
                    break;
                default:
                    break;
            }
        }
        if (status === 'pass' && hasSkip) {
            return 'pass';
        }
        return status;
    }

    /////////////////////////////
    //         Content         //
    /////////////////////////////

    return (
        <div className="section">
            {configs.isManualTest && (
                <Accordion defaultActiveKey={
                    configs.operations.length === 0
                        ? undefined
                        : configs.operations.every((operation: any) => operation.operation.operation?.result === 'pass')
                            ? undefined
                            : "0"
                }>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <div className={["spaceBetweenContainer", styles.accordionHeaderContainer].join(' ')}>
                                <div className="displayFlexCenter">
                                    {configs.operations.length > 0 && (
                                        <StatusTag
                                            status={FhirStatus[prioritizeStatus(configs.operations.map((operation: any) => operation.operation)) as keyof typeof FhirStatus]}
                                            statusMessage={prioritizeStatus(configs.operations.map((operation: any) => operation.operation))}
                                        />
                                    )}
                                    <div className={styles.titleContainer}>
                                        <Title
                                            level={2}
                                            content={configs.titleSection}
                                        />
                                    </div>
                                </div>
                                {configs.operations.length > 0 && (
                                    <div className="displayFlexCenter">
                                        <Proof
                                            serverUrl='https://integ.fyrstain.com/test-engine'
                                            userIdentifier={UserService.getUsername()}
                                            testReportId={configs.testReportId}
                                            testReport={configs.testReport}
                                            testScriptId={configs.testScriptId}
                                            testScript={configs.testScript}
                                            testStep={configs.testStepHeaderOperation}
                                            language={i18n.t}
                                            onError={configs.onError}
                                        />
                                        <Thread
                                            serverUrl='https://integ.fyrstain.com/test-engine'
                                            userIdentifier={UserService.getUsername()}
                                            onError={configs.onError}
                                            testReportId={configs.testReportId}
                                            testReport={configs.testReport}
                                            testScriptId={configs.testScriptId}
                                            testScript={configs.testScript}
                                            testStep={configs.testStepHeaderOperation}
                                            language={i18n.t}
                                        />
                                    </div>
                                )}
                            </div>
                        </Accordion.Header>
                        {configs.operations.length > 0 &&
                            <Accordion.Body>
                                <div className="container col-md-12 panel panel-default panel-body">
                                    <table className="table table-condensed table-striped">
                                        {!headerAdded && (
                                            <thead>
                                                <tr>
                                                    <th>
                                                        {i18n.t('table.row.name')}
                                                    </th>
                                                    <th>
                                                        {i18n.t('table.row.result')}
                                                    </th>
                                                    <th>
                                                        Messages
                                                    </th>
                                                    <th>
                                                        Actions
                                                    </th>
                                                </tr>
                                            </thead>
                                        )}
                                        <tbody>
                                            {configs.operations.map((operation: any) => (
                                                <>
                                                    <tr className={styles.operationRow}>
                                                        <td className={styles.columnsWidthOperation}>
                                                            {configs.testStepOperation(operation)}
                                                        </td>
                                                        <td className={styles.smallColumnsWidthOperation}>
                                                            <TestOperationStatusSelector
                                                                testReport={configs.testReport}
                                                                testReportId={configs.testReportId}
                                                                testStep={configs.testStepOperation(operation)}
                                                                updateType="result"
                                                            />
                                                        </td>
                                                        <td className={styles.largeColumnsWidthOperation}>
                                                            <TestMessageEdition
                                                                testReport={configs.testReport}
                                                                testReportId={configs.testReportId}
                                                                testStep={configs.testStepOperation(operation)}
                                                            />
                                                        </td>
                                                        <td className={styles.smallColumnsWidthOperation}>
                                                            <div className="flexWrapStart">
                                                                {configs.operations.length > 0 && (
                                                                    <div className="displayFlexCenter">
                                                                        <Proof
                                                                            serverUrl='https://integ.fyrstain.com/test-engine'
                                                                            userIdentifier={UserService.getUsername()}
                                                                            onError={configs.onError}
                                                                            testReportId={configs.testReportId}
                                                                            testReport={configs.testReport}
                                                                            testScriptId={configs.testScriptId}
                                                                            testScript={configs.testScript}
                                                                            testStep={configs.testStepOperation(operation)}
                                                                            language={i18n.t}
                                                                        />
                                                                        <Thread
                                                                            serverUrl='https://integ.fyrstain.com/test-engine'
                                                                            userIdentifier={UserService.getUsername()}
                                                                            onError={() => { }}
                                                                            testReportId={configs.testReportId}
                                                                            testReport={configs.testReport}
                                                                            testScriptId={configs.testScriptId}
                                                                            testScript={configs.testScript}
                                                                            testStep={configs.testStepOperation(operation)}
                                                                            language={i18n.t}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {!configs.isTeardown && !operation.toggle && (
                                                                <FontAwesomeIcon
                                                                    className={styles.chevron}
                                                                    icon={faChevronUp}
                                                                    rotation={180}
                                                                    onClick={() => {
                                                                        operation.toggle = !operation.toggle;
                                                                        forceUpdate();
                                                                    }}
                                                                />
                                                            )}
                                                            {!configs.isTeardown && operation.toggle && (
                                                                <FontAwesomeIcon
                                                                    className={styles.chevron}
                                                                    icon={faChevronUp}
                                                                    onClick={() => {
                                                                        operation.toggle = !operation.toggle;
                                                                        forceUpdate();
                                                                    }}
                                                                />
                                                            )}
                                                        </td>
                                                    </tr >
                                                    <tr>
                                                        <td colSpan={12}>
                                                            {!configs.isTeardown && operation.toggle && (
                                                                <>
                                                                    <table className="table table-striped">
                                                                        <tbody>
                                                                            {operation.asserts && operation.asserts.map((assert: any, assertIndex: number) => (
                                                                                <tr key={assert.assert?.id || assertIndex}>
                                                                                    <td className={styles.columnsWidthAssert}>
                                                                                        {assert.assert?.id ?? 'N/A'}
                                                                                    </td>
                                                                                    <td className={styles.smallColumnsWidthAssert}>
                                                                                        <TestOperationStatusSelector
                                                                                            testReport={configs.testReport}
                                                                                            testReportId={configs.testReportId}
                                                                                            testStep={configs.testStepAssert(assert)}
                                                                                            updateType="result"
                                                                                        />
                                                                                    </td>
                                                                                    <td className={styles.largeColumnsWidthAssert}>
                                                                                        <TestMessageEdition
                                                                                            testReport={configs.testReport}
                                                                                            testReportId={configs.testReportId}
                                                                                            testStep={assert.assert?.id ?? 'N/A'}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="flexWrapStart">
                                                                                            {configs.operations.length > 0 && (
                                                                                                <div className="displayFlexCenter">
                                                                                                    <Proof
                                                                                                        serverUrl='https://integ.fyrstain.com/test-engine'
                                                                                                        userIdentifier={UserService.getUsername()}
                                                                                                        onError={configs.onError}
                                                                                                        testReportId={configs.testReportId}
                                                                                                        testReport={configs.testReport}
                                                                                                        testScriptId={configs.testScriptId}
                                                                                                        testScript={configs.testScript}
                                                                                                        testStep={configs.testStepAssert(assert)}
                                                                                                        language={i18n.t}
                                                                                                    />
                                                                                                    <Thread
                                                                                                        serverUrl='https://integ.fyrstain.com/test-engine'
                                                                                                        userIdentifier={UserService.getUsername()}
                                                                                                        onError={configs.onError}
                                                                                                        testReportId={configs.testReportId}
                                                                                                        testReport={configs.testReport}
                                                                                                        testScriptId={configs.testScriptId}
                                                                                                        testScript={configs.testScript}
                                                                                                        testStep={configs.testStepAssert(assert)}
                                                                                                        language={i18n.t}
                                                                                                    />
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                    <AddTestOperation
                                                                        operationType='assert'
                                                                        testReport={configs.testReport}
                                                                        testReportId={configs.testReportId}
                                                                        testStep={configs.addTestAssert}
                                                                        onAddAssert={handleAddAssert}
                                                                        hasOperations={configs.operations.length > 0}
                                                                        operationId={operation.operation.operation?.id}
                                                                    />
                                                                </>
                                                            )}
                                                        </td>
                                                    </tr>
                                                </>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <AddTestOperation
                                    operationType='operation'
                                    testReport={configs.testReport}
                                    testReportId={configs.testReportId}
                                    testStep={configs.addTestOperation}
                                    onAddOperation={handleAddOperation}
                                    hasOperations={configs.operations.length > 0}
                                    testId={configs.testId}
                                />
                            </Accordion.Body>
                        }
                        {configs.operations.length === 0 &&
                            <Accordion.Body>
                                <AddTestOperation
                                    operationType='operation'
                                    testReport={configs.testReport}
                                    testReportId={configs.testReportId}
                                    testStep={configs.addTestOperation}
                                    onAddOperation={handleAddOperation}
                                    hasOperations={configs.operations.length > 0}
                                />
                            </Accordion.Body>
                        }
                    </Accordion.Item>
                </Accordion>
            )}
            {!configs.isManualTest && configs.operations.length > 0 ? (
                <Accordion defaultActiveKey={configs.operations.every((operation: any) => operation.operation.operation?.result === 'pass') ? undefined : "0"}>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <div className={["spaceBetweenContainer", styles.accordionHeaderContainer].join(' ')}>
                                <div className="displayFlexCenter">
                                    <StatusTag
                                        status={FhirStatus[prioritizeStatus(configs.operations.map((operation: any) => operation.operation)) as keyof typeof FhirStatus]}
                                        statusMessage={prioritizeStatus(configs.operations.map((operation: any) => operation.operation))}
                                    />
                                    <div className={styles.titleContainer}>
                                        <Title
                                            level={2}
                                            content={configs.titleSection}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="container col-md-12 panel panel-default panel-body">
                                <table className="table table-condensed table-striped">
                                    <thead>
                                        <tr>
                                            <th>
                                                {i18n.t('table.row.name')}
                                            </th>
                                            <th>
                                                {i18n.t('table.row.result')}
                                            </th>
                                            <th>
                                                Messages
                                            </th>
                                            <th>
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {configs.operations.map((operation: any) => (
                                            <>
                                                <tr className={styles.operationRow}>
                                                    <td className={styles.columnsWidthOperation}>
                                                        {configs.testStepOperation(operation)}
                                                    </td>
                                                    <td className={styles.smallColumnsWidthOperation}>
                                                        <StatusTag
                                                            status={FhirStatus[operation.operation.operation?.result as keyof typeof FhirStatus]}
                                                            statusMessage={operation.operation.operation?.result ?? 'N/A'}
                                                        />
                                                    </td>
                                                    <td className={styles.largeColumnsWidthOperation}>
                                                        {operation.operation.operation?.message ?? 'N/A'}
                                                    </td>
                                                    <td className={styles.smallColumnsWidthOperation}>
                                                        <div className="flexWrapStart">
                                                            <a
                                                                href={configs.onDetail(operation.operation?.detail)}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <FontAwesomeIcon
                                                                    className="actionIcon"
                                                                    icon={faEye}
                                                                />
                                                            </a>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {!operation.toggle && (
                                                            <FontAwesomeIcon
                                                                className={styles.chevron}
                                                                icon={faChevronUp}

                                                                rotation={180}

                                                                onClick={() => {
                                                                    operation.toggle = !operation.toggle;
                                                                    forceUpdate();
                                                                }}
                                                            />
                                                        )}
                                                        {operation.toggle && (
                                                            <FontAwesomeIcon
                                                                className={styles.chevron}
                                                                icon={faChevronUp}
                                                                onClick={() => {
                                                                    operation.toggle = !operation.toggle;
                                                                    forceUpdate();
                                                                }}
                                                            />
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={12}>
                                                        {operation.toggle && !configs.isTeardown && (
                                                            <table className="table table-striped">
                                                                <thead>
                                                                </thead>
                                                                <tbody>
                                                                    {operation.asserts.map((assert: any) => <tr>
                                                                        <td className={styles.columnsWidthAssert}>
                                                                            {assert.assert?.id ?? 'N/A'}
                                                                        </td>
                                                                        <td className={styles.smallColumnsWidthAssert}>
                                                                            <StatusTag
                                                                                status={FhirStatus[assert.assert?.result as keyof typeof FhirStatus]}
                                                                                statusMessage={assert.assert?.result ?? 'N/A'}
                                                                            />
                                                                        </td>
                                                                        <td className={styles.largeColumnsWidthAssert}>
                                                                            {assert.assert?.message ?? 'N/A'}
                                                                        </td>
                                                                        <td>
                                                                            <div className="flexWrapStart">
                                                                                <a
                                                                                    href={configs.onDetail(assert.assert?.detail)}
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                >
                                                                                    <FontAwesomeIcon
                                                                                        className="actionIcon"
                                                                                        icon={faEye}
                                                                                    />
                                                                                </a>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        )}
                                                    </td>
                                                </tr>
                                            </>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            ) : (!configs.isManualTest && configs.operations.length === 0) && (
                <Card>
                    <Card.Header className={styles.noOperationCard}>
                        <Title
                            level={2}
                            content={configs.titleSection}
                        />
                        <b className={styles.infoMessage}>({i18n.t('infomessage.nooperation')})</b>
                    </Card.Header>
                </Card>
            )
            }
        </div >
    );
};

export default TestOperationSection;